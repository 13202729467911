import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { useFiberOrderContext } from './Provider';
import TabSwitch from 'components/forms/TabSwitch';
import ProductWrapper, {
	GridWrapper,
} from 'parts/product-columns/ProductWrapper';
import Button from 'components/forms/Button';
import Heading from 'libs/heading';
import { ProductPrice, SlideUpDown } from './BaseComponents';
import Points from 'parts/product-columns/Points';
import TitleAndText from 'parts/title-and-text/TitleAndText';

const Intro = styled.div`
	margin-bottom: ${p => p.theme.spacing.desktop.small};
	text-align: ${p => p.alignment || 'left'};
	${p =>
		p.theme.media.large(css`
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-bottom: ${p => p.theme.spacing.desktop.medium};
		`)}
	.text,
	p:last-of-type {
		margin-bottom: 0;
	}
`;

const Wrap = styled.div`
	width: 100%;
	position: relative;
`;

const SwitchWrapper = styled.div`
	padding: 20px 0 0 0;
	${p =>
		p.theme.media.large(css`
			padding: 0 0 0 20px;
		`)}
`;

const Form = styled.form`
	margin-top: auto;
`;

const ButtonWrap = styled.div`
	margin-top: auto !important;
	padding-top: ${p => p.theme.spacing.desktop.xsmall};
	${p =>
		p.perrow === 1 &&
		css`
			${p =>
				p.theme.media.medium(css`
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
				`)}
		`}
`;

/**
 * Represents a component for displaying a grid of products, including an optional introduction, toggle, and product details.
 *
 * @param {Object} props - The properties for the ProductGrid component.
 * @param {Array} [props.products=[]] - An array of product data to be displayed.
 * @param {boolean} [props.showToggle=false] - Flag to determine whether to show the toggle switch.
 * @param {number} [props.perrow=3] - Number of products to display per row in the grid.
 * @param {object} [...props] - Additional props to be spread on the component.
 * @returns {ReactNode} - A React element representing the ProductGrid component.
 */
export default function ProductGrid({
	products = [],
	showToggle = false,
	perrow = 3,
	...props
}) {
	const {
		deliverResults,
		loading,
		setChosenProduct,
		view,
		setView,
		mainPageSlug,
		clearChosenAddOns,
	} = useFiberOrderContext();

	const hasIntro = !!(props?.title || props?.component) || !!showToggle;

	return (
		<Wrap data-cy="products-wrapper" {...props}>
			{hasIntro && (
				<Intro {...props}>
					<TitleAndText {...props} nested={true}>
						{props?.component}
					</TitleAndText>
					{showToggle && (
						<SwitchWrapper>
							<TabSwitch
								activeTab={view}
								setActiveTab={setView}
								tabs={[
									{
										id: 'Fiberbredbånd',
										text: 'Internett',
										ariaLabel: 'Vis internett-produkter',
									},
									{
										id: 'Produktpakke',
										text: 'TV og Internett',
										ariaLabel:
											'Vis TV og internett-produkter',
									},
								]}
							/>
						</SwitchWrapper>
					)}
				</Intro>
			)}
			<SlideUpDown
				id="fiber-delivery-products"
				visible={!loading && deliverResults?.result}>
				<>
					{(products?.length > 0 && (
						<GridWrapper
							data-cy="fiber-products"
							perrow={
								(products?.length < perrow &&
									products?.length) ||
								perrow
							}
							total={products?.length}>
							{products.map(item => {
								return (
									<ProductWrapper
										key={item?.slug}
										data-cy="fiber-product"
										perrow={perrow}
										highlighted={
											item?.recommended ? 'true' : 'false'
										}
										anyhighlighted={
											props?.anyhighlighted
												? 'true'
												: 'false'
										}
										className="contentfulinnholdtelekomprodukter"
										header={
											<>
												{item?.title && (
													<Heading
														level={
															props?.headinglevel ||
															'h3'
														}>
														{item?.title}
													</Heading>
												)}
												{item?.description && (
													<p className="product__desc">
														{item?.description}
													</p>
												)}
												{perrow === 1 && (
													<ProductPrice {...item} />
												)}
											</>
										}>
										{perrow !== 1 && (
											<>
												<ProductPrice
													className="bold"
													{...item}
												/>
												<Points
													included={
														item?.included || []
													}
													className="product__features"
												/>
											</>
										)}
										<Form
											onSubmit={e => {
												e.preventDefault();

												// If no title is set and no sub-products exists then return
												if (!e?.target?.title?.value) {
													return;
												}

												// Set chosen product
												setChosenProduct(item);

												// Clear chosen add-ons
												clearChosenAddOns();

												// Navigate to order-form-page
												navigate(
													`/${mainPageSlug}/bestill/steg1`
												);
											}}>
											{item?.priceMonth && (
												<div>
													<input
														type="hidden"
														name="title"
														value={item?.title}
													/>
												</div>
											)}

											<ButtonWrap
												perrow={perrow}
												className="button-wrap">
												<Button
													stretch={true}
													data-cy="fiber-product__select-btn"
													type="submit">
													{`Bestill ${
														(item?.type !==
															'Produktpakke' &&
															item?.mbps &&
															item?.mbps) ||
														item?.title
													}`}
												</Button>
											</ButtonWrap>
										</Form>
									</ProductWrapper>
								);
							})}
						</GridWrapper>
					)) || (
						<p>
							Vi tilbyr desverre ikke noen produkter innenfor
							denne kategorien.
						</p>
					)}
				</>
			</SlideUpDown>
		</Wrap>
	);
}
