import React, { Fragment } from 'react';

import { useFiberOrderContext } from 'components/fiber-2.0/Provider';
import { Fieldset, Req } from 'components/fiber-2.0/order/Styling';

import Checkbox from 'components/forms/NewCheckbox';
import { Paragraphs } from 'components/fiber-2.0/BaseComponents';
import ToolTip from 'components/ToolTip';
import RouterImage from 'images/fiber/routers.png';

export default function TermsAndConditions({
	view = '',
	showCheckboxes = false,
	showToolTip = false,
	...props
}) {
	const { deliverResults, order, setOrder, getTermsByView } =
		useFiberOrderContext();

	// Get the terms for the view
	let terms = view
		? getTermsByView(deliverResults?.deliveryTerms || props?.terms, view)
		: [];

	// If the view is "Kvittering" and there is a thank you text found already, remove the term with id "test"
	if (view === 'Kvittering' && deliverResults?.thankYouText) {
		// remove term with id "takk_for_din_bestilling" from terms
		terms = terms.filter(
			term => term?.text_id !== 'takk_for_din_bestilling'
		);
	}

	/**
	 * Handle checkbox change
	 * @param {Event} e - The event object
	 * @param {Object} term - The term object
	 * @param {Object} paragraph - The paragraph object
	 * @returns {void}
	 */
	function handleCheckboxChange(e, term, paragraph) {
		const isChecked = e.target.checked;
		let updatedOrder = { ...order };

		if (isChecked) {
			e.target.setAttribute('checked', true);

			// if paragraph?.text_id is not already in the order object, add it
			if (
				!order?.godtatte_vilkaar?.find(
					term => term?.id === paragraph?.text_id
				)
			) {
				updatedOrder = {
					...updatedOrder,
					godtatte_vilkaar: [
						...updatedOrder?.godtatte_vilkaar,
						{
							id: paragraph?.text_id,
							tittel: term?.title,
							tekst: paragraph?.text,
							visning: term?.views,
							prioritering: paragraph?.priority,
						},
					],
				};
			}
		} else {
			e.target.removeAttribute('checked');

			// remove paragraph?.text_id from order object "godtatte_vilkaar"
			if (
				order?.godtatte_vilkaar?.find(
					term => term?.id === paragraph?.text_id
				)
			) {
				updatedOrder = {
					...updatedOrder,
					godtatte_vilkaar: updatedOrder?.godtatte_vilkaar.filter(
						term => term?.id !== paragraph?.text_id
					),
				};
			}
		}

		setOrder(updatedOrder);
	}

	return (
		<div data-cy="fiber-order-terms">
			{terms?.length > 0 &&
				terms?.map(term => {
					return (
						<Fieldset key={term?.text_id}>
							{term?.title && (
								<legend id={`${term?.text_id}-label`}>
									{term?.title}
								</legend>
							)}

							{term?.paragraphs?.map((paragraph, i) => {
								const required =
									paragraph?.content_type ===
										'Avkryssing_obligatorisk' || false;

								if (
									[
										'Avkryssing',
										'Avkryssing_obligatorisk',
									].includes(paragraph?.content_type) &&
									showCheckboxes
								) {
									return (
										<div
											key={i}
											className="term"
											data-cy={
												required
													? 'required-term'
													: 'term'
											}>
											<Checkbox
												type="checkbox"
												tabIndex="0"
												id={paragraph?.text_id}
												name={paragraph?.text_id}
												defaultChecked={
													order[paragraph?.text_id]
												}
												data-cy={`fiber-order-form-${paragraph?.text_id}`}
												onChange={e =>
													handleCheckboxChange(
														e,
														term,
														paragraph
													)
												}
												required={required}>
												<Paragraphs
													text={paragraph?.text}
												/>
												{required && (
													<>
														{' '}
														<Req>*</Req>
													</>
												)}
											</Checkbox>
										</div>
									);
								}

								return (
									<Fragment key={i}>
										{term?.text_id ===
											'Aktivering_Eks_LP' &&
											showToolTip &&
											[
												'Inaktivt_Leveransepunkt_Villa',
												'Inaktivt_Leveransepunkt_Villa_Kampanjeområde',
											].includes(
												deliverResults?.deliverable
													?.landing_page
											) && <HomeCentralTooltip />}

										<Paragraphs
											text={paragraph?.text}
											as="div"
										/>
									</Fragment>
								);
							})}
						</Fieldset>
					);
				})}
		</div>
	);
}

/**
 * Tooltip for home central text
 * @returns {JSX.Element} - The tooltip
 **/
function HomeCentralTooltip() {
	return (
		<ToolTip
			id="hjemmesentral"
			position="bottom"
			size="large"
			title="hjemmesentral/ruter"
			aria-label="Hold musepekeren over for å se informasjon om hjemmesentralen/ruteren">
			<div>
				<h3>Hjemmesentralen</h3>
				<p>
					Enten du kaller det Altibox-router, den hvite boksen eller
					hjemmesentral – denne boksen tilkoblingen mellom huset ditt
					og det globale nettverket. Finner du ingen av disse boksene
					hjemme hos deg, ta kontakt med vår kundeservice på tlf. 74
					15 02 00.
				</p>
				<img
					src={RouterImage}
					alt="Ulike hjemmesentraler / rutere"
					style={{ marginTop: '20px' }}
				/>
			</div>
		</ToolTip>
	);
}
