import React from 'react';
import styled from 'styled-components';

import { useFiberOrderContext } from './Provider';
import SelectField from 'components/forms/SelectField';
import { AnchorLink } from 'components/Link';
import { SlideUpDown } from './BaseComponents';

const UnitsDropdown = styled.div`
	padding: 30px 0 25px;
	> div {
		margin-bottom: 0 !important;
		&.help-text {
			margin-bottom: 0 !important;
		}
	}
`;

/**
 * Represents a component for selecting a unit from a list of units.
 * @param {Object} props - The properties for the UnitSelect component.
 * @returns {ReactNode} - A React element representing the UnitSelect component.
 */

export default function UnitSelect(props) {
	const { address, loading } = useFiberOrderContext();

	return (
		<>
			<SlideUpDown
				id="fiber-address-servicepoint-select"
				data-cy="fiber-address-servicepoint-select"
				visible={address?.units?.length > 1 && !loading}>
				<UnitsDropdown>
					<p style={{ marginBottom: '10px' }}>
						Det ser ut som det er flere enheter på denne adressen.
						Se om du finner din her:
					</p>
					<SelectField
						label="Velg enhet"
						helpText={
							<AnchorLink
								href="https://www.kartverket.no/eiendom/adressering/finn-bruksenhetsnummer-bolignummer"
								target="_blank">
								Slik finner du ditt bruksenhetsnummer
							</AnchorLink>
						}
						{...props}
					/>
				</UnitsDropdown>
			</SlideUpDown>
		</>
	);
}
