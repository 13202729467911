import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';

import { useFiberOrderContext } from '../../Provider';
import BlankButton from 'components/forms/BlankButton';
import useWindow from 'context/useWindow';
import ScreenReaderText from 'components/screen-reader-text';
import Box from 'components/forms/Box';
import { useAuth } from 'context/AuthProvider';
import Points from 'parts/product-columns/Points';
import Heading from 'libs/heading';
import { ProductPrice } from 'components/fiber-2.0/BaseComponents';

const Wrap = styled.div`
	text-align: inherit;
`;

const BoxWrap = styled.div`
	${p =>
		p.theme.media.mediumDown(css`
			padding-top: 40px;
		`)}
	.box {
		${p =>
			p.theme.media.large(css`
				min-width: 100%;
			`)};
	}
`;

const ButtonToggle = styled(BlankButton)`
	margin: 20px 0 0;
	${p =>
		p.theme.media.large(css`
			display: none;
		`)}
	svg {
		margin-left: 10px;
	}
`;

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	> li {
		&:not(:first-of-type) {
			padding-top: 20px;
			border-top: 1px solid ${p => p.theme.colors.grey300};
			${p =>
				p.theme.media.large(css`
					padding-top: 30px;
				`)}
		}
		&:not(:last-of-type) {
			padding-bottom: 20px;
			${p =>
				p.theme.media.large(css`
					padding-bottom: 30px;
				`)}
		}

		.product__title {
			margin-bottom: 10px;
		}
		.product__price {
			margin-top: 20px;
		}
		.product__features {
			margin-top: 20px;
			li:last-of-type {
				margin-bottom: 0;
			}
		}
	}
`;

const Text = styled.div`
	margin-top: 20px;
	button,
	p {
		font-size: 14px;
		line-height: 22px;
		margin: 0;
	}
`;

/**
 * Represents a component for displaying an order summary.
 * @param {Object} props - The properties for the OrderSummary component.
 * @param {boolean} [props.box=false] - Indicates if the order summary should be displayed in a box.
 * @param {boolean} [props.bottomText=false] - Indicates if the order summary should display a text below the box.
 * @param {boolean} [props.minimalizedOnMobile=true] - Indicates if the order summary should be minimalized on mobile.
 * @returns {ReactNode} - A React element representing the OrderSummary component.
 **/
export default function OrderSummary({
	box = false,
	bottomText = false,
	minimalizedOnMobile = true,
	...props
}) {
	const [openDiv, setOpenDiv] = useState(false);

	const { address, mainPageSlug, chosenProduct, chosenAddOns } =
		useFiberOrderContext();
	const { isAuthenticated } = useAuth();
	const { windowWidth } = useWindow();

	const isMobile = windowWidth <= 1099;

	const product = chosenProduct?.title ? chosenProduct : props?.product;
	const addOns = chosenAddOns?.length > 0 ? chosenAddOns : props?.addOns;

	return (
		<Wrap data-cy="fiber-order-product-summary">
			{props?.minimalized && (
				<ButtonToggle
					onClick={() => setOpenDiv(!openDiv)}
					aria-expanded={openDiv}>
					<span>
						{(openDiv && 'Vis mindre') || 'Vis mer'} informasjon om
						bestillingen
					</span>
					<FontAwesomeIcon
						icon={(openDiv && faAngleUp) || faAngleDown}
						width="13"
						height="24"
					/>
					<ScreenReaderText>{`${
						(openDiv && 'Vis mindre') || 'Vis mer'
					} informasjon om bestillingen`}</ScreenReaderText>
				</ButtonToggle>
			)}

			<AnimatePresence>
				{((props?.minimalized && isMobile && openDiv) ||
					(!props?.minimalized && !isMobile) ||
					!minimalizedOnMobile) && (
					<motion.div
						id="order-summary"
						initial={(props?.minimalized && 'collapsed') || 'open'}
						animate="open"
						exit="collapsed"
						variants={{
							open: {
								opacity: 1,
								height: 'auto',
							},
							collapsed: {
								opacity: 0,
								height: 0,
							},
						}}
						transition={{
							duration: 0.15,
						}}>
						<BoxWrap>
							<Box
								badge="Du har valgt"
								alignment={props?.alignment}>
								{(!!product || chosenAddOns?.length > 0) && (
									<List>
										{Object.keys(product)?.length > 0 && (
											<li key={product?.slug}>
												<ProductItem
													{...props}
													{...product}
												/>
											</li>
										)}

										{addOns?.length > 0 &&
											props?.showAddOns && (
												<>
													{addOns?.map(a => (
														<li key={a?.slug}>
															<ProductItem
																{...props}
																{...a}
															/>
														</li>
													))}
												</>
											)}
									</List>
								)}
							</Box>
						</BoxWrap>
					</motion.div>
				)}
			</AnimatePresence>
			{bottomText && !isAuthenticated && (
				<Text>
					<BlankButton
						title="Gå tilbake til produktoversikten"
						onClick={e => {
							e.preventDefault();
							navigate(`/${mainPageSlug}?se=alle`);
						}}>
						Se alle produktene du kan få til {address?.result}.
					</BlankButton>
				</Text>
			)}
		</Wrap>
	);
}

/**
 * Represents a component for displaying a product item.
 * @param {object} props
 * @param {boolean} props.showIncluded - Show included features
 * @param {boolean} props.showNotIncluded - Show not included features
 * @param {string} props.headinglevel - Heading level
 * @param {string} props.className - Additional class name
 * @param {string} props.title - Product title
 * @param {string} props.description - Product description
 * @param {boolean} props.showDescription - Whether to show product description or not
 * @param {string} props.type - Product type
 * @param {string} props.priceMonth - Product price per month
 * @param {string} props.priceSetup - Product price for setup
 * @param {array} props.included - Product included points
 * @param {array} props.notIncluded - Product not included points
 * @returns {ReactNode} - A React element representing the ProductItem component.
 */
function ProductItem({
	showIncluded = true,
	showNotIncluded = false,
	showDescription = true,
	...props
}) {
	return (
		<Wrap
			className={
				(props?.className && `product__item ${props?.className}`) ||
				'product__item'
			}>
			{props?.title && (
				<Heading
					level={props?.headinglevel || 'h3'}
					className="product__title">
					{props?.title}
				</Heading>
			)}
			{showDescription !== false && props?.description && (
				<p className="product__desc">{props?.description}</p>
			)}

			{props?.quantity > 0 && (
				<p className="product__quantity">Antall: {props?.quantity}</p>
			)}

			<ProductPrice {...props} />

			<Points
				included={(showIncluded && props?.included) || []}
				notIncluded={!!showNotIncluded && props?.notIncluded}
				className="product__features"
			/>
		</Wrap>
	);
}
