import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { useApolloClient, gql } from '@apollo/client';

import { useFiberOrderContext } from '../../Provider';
import { scrollToElement } from 'libs/content';
import MediumWidth from 'layouts/medium-width';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import ProductSelect from './ProductSelect';
import Button from 'components/forms/Button';
import Link from 'components/Link';
import { useAuth } from 'context/AuthProvider';
import Loading from 'components/Loading';
import Error from 'components/error/Error';
import { trackBackEnd } from 'context/AnalyticsProvider';
import LeadForm from 'components/fiber-2.0/LeadForm';

const Actions = styled.div`
	margin-top: 45px;
	text-align: center;
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 30px;
		`)}
`;

export default function AddOnOffer() {
	const [saving, setSaving] = useState(false);

	const client = useApolloClient();
	const { loading: authLoading } = useAuth();
	const {
		addOns,
		setChosenAddOns,
		chosenProduct,
		order,
		setOrder,
		mainPageSlug,
		loading,
		errors,
		setErrors,
		deliverResults,
	} = useFiberOrderContext();

	// Get the add-on to offer based on the chosen product's type (Fiberbredbånd / Produktpakke)
	const addOnToOffer =
		(chosenProduct?.type &&
			addOns?.find(a => a?.productType?.includes(chosenProduct?.type))) ||
		{};

	// Rename the add-on type
	const addOnType =
		addOnToOffer?.type === 'Fiberbredbånd' ? 'Wifi' : 'TV-underholdning';

	async function onSubmit(e) {
		e.preventDefault();

		try {
			setSaving(true);

			if (!addOnToOffer) {
				throw new Error('Kunne ikke finne tilleggsprodukt');
			}

			if (!order?.ordre_id) {
				throw new Error('Kunne ikke finne ordre-ID');
			}

			setChosenAddOns({ ...addOnToOffer, quantity: 1 });

			setOrder({
				...order,
				tilleggsprodukter: {
					tittel: addOnToOffer?.title,
					antall: 1,
				},
			});

			const { data: orderAddOnData, error: orderAddOnError } =
				await client.mutate({
					mutation: gql`
						mutation orderFiberAddOn(
							$order_id: String!
							$title: String!
							$quantity: Int
						) {
							orderFiberAddOn(
								order_id: $order_id
								title: $title
								quantity: $quantity
							)
						}
					`,
					variables: {
						order_id: order?.ordre_id,
						title: addOnToOffer?.title,
						quantity: 1,
					},
					skip: !order?.ordre_id || !addOnToOffer?.title,
				});

			if (orderAddOnError || !orderAddOnData?.orderFiberAddOn) {
				setErrors([
					'Noe gikk galt under bestilling av tilleggsprodukt',
				]);
				throw new Error(orderAddOnError?.message);
			}

			trackBackEnd('Product Added', {
				order_id: order?.ordre_id,
				product_id: addOnToOffer?.title,
				landing_page: deliverResults?.deliverable?.landing_page,
				category: 'TV og Internett',
				name: addOnToOffer?.title,
				quantity: 1,
				method: 'Oppsalg',
			});

			navigate(`/${mainPageSlug}/bestill/takk`);
		} catch (error) {
			console.log('Fiber add-on order submit error', error);
		}
	}

	// Scroll to top of page
	useEffect(() => {
		scrollToElement(document?.getElementById('add-on-offer'), 300);
	}, []);

	// If user is not authenticated yet or loading, show loading-component
	if (authLoading || loading) {
		return <Loading text="Laster ..." style={{ paddingTop: '60px' }} />;
	}

	return (
		<div id="add-on-offer">
			<section>
				<TitleAndText
					title="En siste ting.."
					text={
						(addOnType === 'Wifi' &&
							'Vi vet at mange ønsker raskere internett. Det hjelper dog ikke alltid å øke båndbredden. Løsningen er ofte å ha flere rutere som snakker sammen.') ||
						'Kanskje det er på tide å oppgradere TV-underholdningen? Vi har flere tilleggsprodukter som kan gi deg en bedre TV-opplevelse.'
					}
					headinglevel="h1"
					isfirstheading={true}
					componentindex={0}
					settings={['Midtstilt tekst']}
					spacing={{ bottom: 'medium' }}
				/>
			</section>
			<section>
				<MediumWidth>
					<Spacing>
						<form onSubmit={onSubmit}>
							<ProductSelect
								key={addOnToOffer?.slug}
								badge="Engangstilbud"
								layout="vertical"
								className="bold"
								category="add-on"
								{...addOnToOffer}
							/>
							<Actions>
								<Button
									type="submit"
									data-cy="fiber-order-form-accept-addon"
									loading={saving?.toString()}
									disabled={saving}>
									{saving
										? 'Oppdaterer bestillingen'
										: `Ja takk, jeg vil ha bedre ${addOnType}`}
								</Button>
							</Actions>
						</form>
						<p style={{ textAlign: 'center', margin: '20px 0 0' }}>
							<Link
								to={`/${mainPageSlug}/bestill/takk`}
								data-cy="fiber-order-form-decline-addon"
								title={`Nei takk, jeg vil ikke ha bedre ${addOnType}`}>
								Nei takk, jeg vil ikke ha bedre {addOnType}
							</Link>
						</p>

						{errors?.length > 0 && (
							<Error
								centered={true}
								text={`${errors?.[0]}.`}
								style={{ marginTop: '45px' }}
								errorMessage={
									errors?.[0] || 'Order fiber add-on failed'
								}
								resolver="orderFiberAddOn"
								popup={
									<LeadForm
										title="Kontakt oss"
										formTitle={null}
										context={`Feil med bestilling av tilleggsprodukt i fiberbestilling: ${errors?.[0]}. Resolver: "orderFiberAddOn"`}
										text="Gi oss beskjed om feilen, så tar vi kontakt med deg."
										showLinkToDevelopmentPage={false}
										shadow={false}
										spacing={{
											top: 'none',
											bottom: 'none',
										}}
										style={{ padding: '0' }}
									/>
								}
							/>
						)}
					</Spacing>
				</MediumWidth>
			</section>
		</div>
	);
}
