import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/nb';

import { useAuth } from 'context/AuthProvider';
import { useFiberOrderContext } from 'components/fiber-2.0/Provider';
import { formatPhoneNumber } from 'libs/content';
import { Fieldset, OptionGroup, Row } from '../Styling';
import InputField from 'components/forms/InputField';
import Radio from 'components/forms/Radio';
import DatePicker from 'components/forms/DatePicker';
import useInputValidation from 'components/forms/useInputValidation';
import AddressSearch from 'components/fiber-2.0/AddressSearch';

dayjs.locale('nb');
dayjs.extend(customParseFormat);

export default function ContactInfo({ errors }) {
	const { order, setOrder, deliverResults } = useFiberOrderContext();
	const { user } = useAuth();
	const { hasError } = useInputValidation();

	const birthday =
		order?.fodselsdato ||
		dayjs(user?.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY');

	return (
		<>
			<Fieldset>
				<legend id="contact-info-label">Kontaktinformasjon</legend>
				<Row
					style={{ marginTop: '20px' }}
					aria-labelledby="contact-info-label">
					<InputField
						id="fornavn"
						name="fornavn"
						label="Fornavn"
						data-cy="fiber-order-form-firstname"
						defaultValue={order?.fornavn || user?.given_name || ''}
						required
					/>
					<InputField
						id="etternavn"
						name="etternavn"
						label="Etternavn"
						data-cy="fiber-order-form-lastname"
						defaultValue={
							order?.etternavn || user?.family_name || ''
						}
						required
					/>
				</Row>
				<Row>
					<InputField
						type="tel"
						id="telefon"
						name="telefon"
						label="Telefon"
						data-cy="fiber-order-form-phone"
						defaultValue={
							(order?.telefon &&
								formatPhoneNumber(order?.telefon)) ||
							(user?.phone && formatPhoneNumber(user?.phone))
						}
						error={
							hasError('telefon', errors) &&
							'Ikke gyldig telefonnummer'
						}
						required
					/>

					<InputField
						type="email"
						id="epost"
						name="epost"
						label="E-post"
						data-cy="fiber-order-form-email"
						defaultValue={order?.epost || user?.email || ''}
						error={
							hasError('epost', errors) &&
							'Ikke gyldig e-postadresse'
						}
						required
					/>
				</Row>
				<Row>
					<DatePicker
						id="fodselsdato"
						name="fodselsdato"
						label="Din fødselsdato"
						error={
							(hasError('fodselsdato', errors) &&
								'Du må være minimum 18 år for å kunne bestille') ||
							(hasError('fodselsdato', errors) &&
								'Du må skrive inn en gyldig dato med format DD/MM/ÅÅÅÅ.')
						}
						defaultValue={birthday}
						maxDate={dayjs().subtract(18, 'year').toDate()}
						dataCy="fiber-order-form-birthdate"
						helpText="Du må være minimum 18 år for å bestille."
						style={{ marginBottom: 0 }}
						required
					/>
				</Row>
			</Fieldset>
			<Fieldset>
				<legend id="oppstart-label">Oppstart</legend>
				<p style={{ marginTop: 0 }}>
					Vi leverer så snart som mulig, men om du ønsker å utsette
					oppstarten av dine tjenester pga. oppsigelsestid, flytting
					e.l. kan du velge ønsket oppstartsdato.
				</p>
				<OptionGroup
					className="radiobuttons"
					aria-labelledby="oppstart-label"
					id="oppstart">
					<li>
						<Radio
							type="radio"
							tabIndex="0"
							id="snart-som-mulig"
							name="oppstart"
							data-cy="fiber-order-form-start-date-asap"
							label={
								(deliverResults?.deliverable?.activation_time &&
									`Så snart som mulig. ${deliverResults?.deliverable?.activation_time}.`) ||
								'Så snart som mulig.'
							}
							defaultChecked={
								order?.oppstart?.beskrivelse !==
								'Jeg vil utsette oppstartsdato'
							}
							onChange={e => {
								if (e.target.checked) {
									e.target.setAttribute('checked', true);
									setOrder({
										oppstart: {
											beskrivelse:
												(deliverResults?.deliverable
													?.activation_time &&
													`Så snart som mulig. ${deliverResults?.deliverable?.activation_time}.`) ||
												'Så snart som mulig.',
										},
									});
								} else {
									e.target.removeAttribute('checked');
								}
							}}
						/>
					</li>
					<li>
						<Radio
							type="radio"
							tabIndex="0"
							id="spesifisert-oppstartsdato"
							name="oppstart"
							data-cy="fiber-order-form-start-date-specified"
							label="Jeg vil utsette oppstartsdato."
							defaultChecked={
								order?.oppstart?.beskrivelse ===
								'Jeg vil utsette oppstartsdato'
							}
							onChange={e => {
								if (e.target.checked) {
									e.target.setAttribute('checked', true);
									setOrder({
										oppstart: {
											beskrivelse:
												'Jeg vil utsette oppstartsdato',
										},
									});
								} else {
									e.target.removeAttribute('checked');
								}
							}}
						/>
					</li>
				</OptionGroup>
				{order?.oppstart?.beskrivelse ===
					'Jeg vil utsette oppstartsdato' && (
					<div style={{ marginTop: '20px' }}>
						<DatePicker
							id="oppstartsdato"
							name="oppstartsdato"
							label="Velg ønsket oppstartsdato"
							error={
								hasError('oppstartsdato', errors)
									? 'Du må velge en dato som er frem i tid.'
									: hasError('oppstartsdato', errors)
									? 'Du må skrive inn en gyldig dato med format DD/MM/ÅÅÅÅ.'
									: false
							}
							value={order?.oppstart?.oppstartsdato}
							dataCy="fiber-order-form-start-date"
							maxDate={dayjs().add(12, 'month').toDate()}
							minDate={dayjs().add(1, 'day').toDate()}
							helpText="Maks 12 måneder frem i tid."
							required
						/>
					</div>
				)}
			</Fieldset>
			<Fieldset>
				<legend id="fakturering-label">Adresse for fakturering</legend>
				<OptionGroup
					className="radiobuttons"
					aria-labelledby="fakturering-label"
					id="fakturering">
					<li>
						<Radio
							type="radio"
							tabIndex="0"
							id="samme-faktureringsaddresse"
							name="fakturering"
							data-cy="fiber-order-form-same-invoice-address"
							label="Samme som leveringsadresse"
							defaultChecked={!order?.annen_faktureringsaddresse}
							onChange={e => {
								if (e.target.checked) {
									e.target.setAttribute('checked', true);
									setOrder({
										annen_faktureringsaddresse: false,
									});
								} else {
									e.target.removeAttribute('checked');
								}
							}}
						/>
					</li>
					<li>
						<Radio
							type="radio"
							tabIndex="0"
							id="annen-faktureringsaddresse"
							name="fakturering"
							data-cy="fiber-order-form-different-invoice-address"
							label="Jeg ønsker annen fakturaadresse"
							defaultChecked={order?.annen_faktureringsaddresse}
							onChange={e => {
								if (e.target.checked) {
									e.target.setAttribute('checked', true);
									setOrder({
										annen_faktureringsaddresse: true,
									});
								} else {
									e.target.removeAttribute('checked');
								}
							}}
						/>
					</li>
				</OptionGroup>

				{order?.annen_faktureringsaddresse && (
					<div style={{ marginTop: '20px' }}>
						<AddressSearch
							defaultValue={order?.faktureringsadresse || ''}
							inputProps={{
								id: 'faktureringsadresse',
								label: 'Fakturaadresse',
								name: 'faktureringsadresse',
								required: true,
							}}
						/>
					</div>
				)}
			</Fieldset>
		</>
	);
}
