import BlankButton from 'components/forms/BlankButton';
import styled, { css } from 'styled-components';

export const Fieldset = styled.div`
	margin-bottom: 45px;
	${p =>
		p.theme.media.smallOnly(css`
			margin-bottom: 30px;
		`)}
	legend {
		font-weight: 500;
		font-size: 24px;
		line-height: 31px;
		margin-bottom: 10px !important;
		&.small {
			font-size: 20px;
			line-height: 26px;
		}
	}
	label .label {
		line-height: 29px;
	}
	p {
		margin-bottom: 10px !important;

		&.help-text {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			margin-top: 15px;
		}
	}

	.term {
		margin-bottom: 20px !important;

		&:last-child {
			margin-bottom: 0 !important;
		}
		> label {
			margin-bottom: 0;
		}
	}
`;

export const Row = styled.div`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: 20px;
			> div {
				flex: 0 1 50%;
				&:only-child {
					flex: 0 1 calc(50% - 10px);
				}
			}
		`)}
	.datepicker > div {
		margin-bottom: 0;
	}
`;

export const OptionGroup = styled.ul`
	list-style: none;
	margin: 20px 0 0;
	padding: 0 20px 0 0;
	li:last-of-type label {
		margin-bottom: 0 !important;
	}
	> li label {
		margin-bottom: 15px;
	}
	&.checkboxes {
		> li label {
			margin-bottom: 20px;
		}
	}
`;

export const ProductOptions = styled.div`
	margin-top: 20px;
`;

export const FieldDesc = styled.p`
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin-top: 15px;
`;

export const Req = styled.span`
	color: ${p => p.theme.colors.coral800};
`;

export const BottomActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	${p =>
		p.theme.media.XSmallOnly(css`
			flex-direction: column;
			align-items: flex-start;
		`)}
	button[type='submit'] {
		margin-right: 20px;
		${p =>
			p.theme.media.XSmallOnly(css`
				margin-right: 0;
			`)}
	}
`;

export const GoBackButton = styled(BlankButton)`
	${p =>
		p.theme.media.XSmallOnly(css`
			margin-top: 30px;
		`)}
`;

export const CheckboxLabel = styled.div`
	font-weight: 500;
	margin: 0 0 10px;
`;
