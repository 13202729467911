import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useFiberOrderContext } from '../Provider';
import { SlideUpDown } from '../BaseComponents';
import Campaign from './Campaign';
import Partner from './Partner';
import LeadForm from '../LeadForm';
import Brl from './Brl';
import { scrollToElement } from 'libs/content';
import Deliverable from './Deliverable';
import { trackBackEnd } from 'context/AnalyticsProvider';

const ResultWrap = styled.div`
	padding-top: ${p => p.theme.spacing.desktop.small};
	${p =>
		p.theme.media.mediumOnly(css`
			padding-top: ${p => p.theme.spacing.tablet.small};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			padding-top: ${p => p.theme.spacing.mobile.small};
		`)}
`;

/**
 * Represents a component for displaying fiber delivery results based on the user's address and selected view.
 *
 * @param {Object} props - The properties for the Result component.
 * @returns {ReactNode} - A React element representing the Result component.
 */
export default function Result({ loading, error }) {
	const { deliverResults, loading: contextLoading } = useFiberOrderContext();

	let props = {};

	// Map the deliverResults result to the correct component
	const componentMap = {
		'can-deliver': Deliverable,
		campaign: Campaign,
		brl: Brl,
		partner: Partner,
		lead: LeadForm,
	};

	// Get the correct component based on the deliverResults result, or use the Error component
	let ResultComponent = deliverResults?.result
		? componentMap[deliverResults?.result]
		: () => null;

	let showResults =
		(deliverResults?.checked && deliverResults?.result) ||
		(deliverResults?.result === 'lead' && !!deliverResults?.code);

	// If there is an error, show the error message and the lead form
	if (!(loading || contextLoading) && error?.length > 0) {
		showResults = true;
		ResultComponent = LeadForm;

		if (error === 'address not found') {
			props.context = 'typo-or-no-result';
		} else {
			props.context = error;
			props.title = 'Vi har problemer med søket for øyeblikket.';
			props.text =
				'Vennligst prøv igjen eller kontakt oss via skjemaet nedenfor.';
		}
	}

	useEffect(() => {
		if (!showResults) return;

		// Scroll to result when it is available
		scrollToElement(document?.getElementById('fiber-delivery-result'), 300);

		// Track the search when the result is available and there is an error
		if (!error?.length > 0) return;

		trackBackEnd('Address Searched', {
			landing_page: deliverResults?.result?.landing_page,
			query: deliverResults?.searchKeyword,
			reason:
				error === 'address not found'
					? 'Could not find address, possible typo'
					: `Backend error: ${error}`,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showResults]);

	return (
		<SlideUpDown id="fiber-delivery-result" visible={showResults}>
			<ResultWrap>
				<ResultComponent {...props} />
			</ResultWrap>
		</SlideUpDown>
	);
}
