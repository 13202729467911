import React from 'react';
import { navigate } from 'gatsby';

import { useAuth } from 'context/AuthProvider';
import { useFiberOrderContext } from 'components/fiber-2.0/Provider';
import { OrderFormContent } from '../BaseComponents';
import AuthButtons from 'components/authentication/AuthButtons';
import AuthInformation from 'components/authentication/AuthInformation';
import OrderSummary from 'components/fiber-2.0/order/fields/OrderSummary';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Link from 'components/Link';
import Loading from 'components/Loading';
import Redirect from 'components/Redirect';
import Step1 from './Step1';
import Step2 from './Step2';
import AuthLogOut from 'components/authentication/AuthLogOut';

export default function OrderPage() {
	const {
		step,
		loading,
		address,
		mainPageSlug,
		deliverResults,
		chosenAddOns,
		chosenProduct,
		flushFiberContext,
	} = useFiberOrderContext();
	const { loading: authLoading, isAuthenticated } = useAuth();

	const isBRL = deliverResults?.result === 'brl'; // BRL = Borettslag

	// If user is not authenticated yet or loading, show loading-component
	if (authLoading || loading) {
		return <Loading text="Laster ..." style={{ paddingTop: '60px' }} />;
	}

	// Redirect to main page if no deliverResults exists, no product chosen or result is lead or partner
	if (
		Object.keys(chosenProduct).length === 0 ||
		Object.keys(deliverResults).length === 0 ||
		['lead', 'partner'].includes(deliverResults?.result)
	) {
		return <Redirect to={`/${mainPageSlug}`} />;
	}

	return (
		<OrderFormContent
			step={step}
			isBRL={isBRL || false}
			centered={isBRL && step === 1}
			showSummaryMobile={(isBRL && step === 2) || !isBRL}
			header={
				<TitleAndText
					title="Bestill fiberbredbånd fra NTE"
					text={undefined}
					isfirstheading={true}
					componentindex={0}
					nested={true}>
					<>
						<p>
							<span>Til {address?.result}</span>
							{address?.units?.length > 1 &&
								address?.dwelling_unit && (
									<span>
										{' '}
										- Enhet {address?.dwelling_unit}
									</span>
								)}
						</p>
						<p>
							<small>
								Feil adresse?{' '}
								<Link
									to={`/${mainPageSlug}`}
									title="Gå tilbake for å sjekke en annen adresse">
									Sjekk om vi kan levere til riktig adresse
								</Link>
								.
							</small>
						</p>
					</>
				</TitleAndText>
			}
			sideContent={
				<OrderSummary
					box={true}
					bottomText={
						(!isBRL && !chosenAddOns?.length > 0 && step === 1) ||
						(!isBRL && chosenAddOns?.length > 0 && step !== 2) ||
						(!isBRL && !chosenAddOns?.length > 0 && step === 2)
					}
					className="bold"
					step={step}
				/>
			}
			footer={
				<AuthLogOut
					style={{ textAlign: 'right', marginTop: '60px' }}
					onClick={() => {
						flushFiberContext();

						// Redirect to main page
						navigate('/');
					}}
				/>
			}>
			<>
				{/** If is not BRL and not authenticated */}
				{(!(isBRL || isAuthenticated) && (
					<>
						<AuthButtons
							title="Først må vi vite hvem du er"
							text="For å vite sikkert at du er den du sier, trenger vi at du identifiserer deg."
							redirect={`/${mainPageSlug}/bestill/steg1`}
							headinglevel="h2"
						/>
						<AuthInformation />
					</>
				)) || (
					<>
						{(step === 1 && <Step1 isBRL={isBRL} />) ||
							(step === 2 && <Step2 isBRL={isBRL} />)}
					</>
				)}
			</>
		</OrderFormContent>
	);
}
