import React from 'react';
import styled from 'styled-components';

import { useFiberOrderContext } from '../Provider';
import MediumWidth from 'layouts/medium-width';
import Spacing from 'layouts/Spacing';
import { AnchorLink } from 'components/Link';
import TitleAndText from 'parts/title-and-text/TitleAndText';

const MediumWidthWrap = styled(MediumWidth)`
	padding: 0;
`;

export default function DeliverByPartner() {
	const { deliverResults } = useFiberOrderContext();

	const partner = deliverResults?.partner?.show && deliverResults?.partner;
	const website = partner && new URL(partner?.website);
	const domain =
		(website?.hostname?.startsWith('www.') &&
			website?.hostname?.slice(4)) ||
		website?.hostname;

	return (
		<MediumWidthWrap id="fiber-partner">
			<Spacing spacing={{ top: 'small', bottom: 'none' }}>
				<TitleAndText
					title={`Du bor i et område dekket av ${
						partner?.name || 'en annen Altibox-partner'
					}`}
					nested={true}
					buttons={[
						{
							href: partner?.website || 'https://altibox.no',
							icon: 'faUpRightFromSquare',
							target: '_blank',
							text: `Gå til ${partner?.name || 'Altibox.no'}`,
							title: `Gå til nettsiden til ${
								partner?.name || 'Altibox'
							} for å finne partner`,
						},
					]}>
					<>
						{(partner?.name && (
							<p>
								Besøk{' '}
								<AnchorLink
									href={partner?.website}
									title={`Gå til nettsiden til ${partner?.name} for å se pakkeløsningene de tilbyr`}
									target="_blank">
									{domain}
								</AnchorLink>{' '}
								og se pakkeløsningene de tilbyr.
							</p>
						)) || (
							<p>
								Finn riktig partner på{' '}
								<AnchorLink
									href="https://altibox.no"
									title="Gå til nettsiden til Altibox for å finne partner"
									target="_blank">
									altibox.no
								</AnchorLink>
								.
							</p>
						)}
					</>
				</TitleAndText>
			</Spacing>
		</MediumWidthWrap>
	);
}
