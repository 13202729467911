import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';

import Button from 'components/forms/Button';
import Points from 'parts/product-columns/Points';
import MissingImage, {
	ProductPrice,
} from 'components/fiber-2.0/BaseComponents';
import { useFiberOrderContext } from 'components/fiber-2.0/Provider';
import Heading from 'libs/heading';
import LazyImage from 'components/LazyImage';
import QtySelect from 'components/forms/QtySelect';

const Inner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${p =>
		p.theme.media.XSmallOnly(css`
			flex-direction: column;
		`)}
`;

const Image = styled.div`
	flex: ${p => (p.$large && '1 0 300px') || '0 0 120px'};
	max-width: ${p => (p.$large && '300px') || '120px'};
	${p =>
		p.category === 'brl' &&
		css`
			flex: ${p => (p.$large && '1 0 300px') || '0 0 173px'};
			max-width: ${p => (p.$large && '300px') || '173px'};
		`}
	${p =>
		p.theme.media.XSmallOnly(css`
			flex: 1 0 120px;
		`)}
	img {
		display: block;
	}
`;

const Content = styled.div`
	flex-grow: 1;
	padding: 0 20px;
	${p =>
		p.theme.media.XSmallOnly(css`
			padding: 10px 0 20px;
			text-align: center;
		`)}
	p {
		margin: 0;
	}
	.product-select__features {
		margin-top: 10px;
		padding-left: 30px;
		font-size: 14px;
		line-height: 22px;
		li {
			margin-bottom: 5px;
			svg {
				width: 16px !important;
				height: 19px !important;
				left: -30px;
			}
		}
	}
`;

const Title = styled.p`
	font-weight: 500;
	font-size: 17px;
	line-height: 29px;
`;

const Action = styled.div`
	flex: 1 0 93px;
	min-width: fit-content;
	text-align: right;
	${p =>
		p.theme.media.XSmallOnly(css`
			text-align: center;
			flex: 1 1 100%;
		`)}

	> .qty-select {
		align-items: flex-end;
	}

	${p =>
		p.alreadyAdded &&
		css`
			button {
				color: ${p => p.theme.colors.coral700} !important;
				outline: none !important;
				text-decoration: underline !important;
				&:hover {
					color: ${p => p.theme.colors.coral800} !important;
				}
			}
		`}
`;

const Wrap = styled.div`
	background: white;
	border-radius: ${p => p.theme.utils.borderRadius};
	box-shadow: ${p => p.theme.utils.boxShadow};
	padding: 20px;
	position: relative;
	text-align: left;
	transition: border-color 0.3s ease;
	${p =>
		p.theme.media.XSmallOnly(css`
			padding: 20px 10px;
		`)}

	// Vertical layout
	&.layout-vertical {
		text-align: center;
		padding: 30px 30px 40px;
		${Inner} {
			flex-direction: column;
		}
		${Content} {
			padding-bottom: 0;
		}
		.product-select__title,
		.product-select__desc {
			margin-bottom: 10px;
		}
		${Image} {
			padding-bottom: 20px;
			${p =>
				p.theme.media.smallOnly(css`
					flex: 1 1 auto;
				`)}
			.lazy-image {
				${p =>
					p.theme.media.smallOnly(css`
						width: 200px;
					`)}
			}
		}
	}

	// Horizontal layout
	${p =>
		p.$layout !== 'vertical' &&
		css`
			border: 3px solid transparent;
			&.error {
				border-color: ${p => p.theme.colors.coral700};
			}
			&.selected {
				border-color: ${p => p.theme.colors.blue600};
			}
			h3 {
				font-size: 17px;
				line-height: 29px;
				font-weight: 600;
			}
			.product-select__desc {
				font-size: 14px;
				line-height: 22px;
			}
			.product-select__price {
				span,
				em {
					font-weight: 400;
					font-size: 17px;
					line-height: 29px;
				}
			}
		`}

	h2,
	h3,
	h4 {
		margin: 0;
	}
	p {
		margin: 0;
		/* font-size: 14px;
		line-height: 22px; */
	}
	&:not(:last-of-type) {
		margin-bottom: 20px;
	}
`;

const Badge = styled.div`
	width: 149px;
	height: 149px;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	span {
		position: absolute;
		right: -22px;
		top: 35px;
		transform: rotate(-45deg);
		display: block;
		width: 225px;
		padding: 8px 0px;
		background-color: ${p => p.theme.colors.green700};
		color: ${p => p.theme.colors.green100};
		text-transform: uppercase;
		font-size: 17px;
		line-height: 29px;
		font-weight: 500;
		text-align: center;
	}
`;

/**
 * ProductSelect component
 * @param {string} title - The title of the product
 * @param {string} image - The image of the product
 * @param {string} description - The description of the product
 * @param {array} included - The included features of the product
 * @param {array} notIncluded - The not included features of the product
 * @param {function} onSelect - The function to call when the product is selected
 * @param {boolean} removeAllowed - If the product can be removed
 * @param {boolean} multiple - If the product can be selected multiple times
 * @param {string} layout - The layout of the product
 * @param {string} badge - The badge of the product
 * @param {string} type - The type of the product
 * @param {string} className - The class name of the product
 * @param {object} props - The props of the product
 * @returns {React.Component} The ProductSelect component
 */
export default function ProductSelect({
	title = '',
	image = '',
	description = '',
	included = [],
	notIncluded = [],
	onSelect = () => {},
	removeAllowed = true,
	multiple = false,
	...props
}) {
	const { chosenProduct, chosenAddOns } = useFiberOrderContext();
	const [showRemove, setShowRemove] = useState(false);
	const [hasClicked, setHasClicked] = useState(false);
	const [quantity, setQuantity] = useState(0);
	const mouseHasHovered = useRef(false);

	// Check if the product is selected
	const isSelected =
		(props?.category === 'add-on' &&
			chosenAddOns?.length > 0 &&
			chosenAddOns?.some(a => a?.slug === props?.slug)) ||
		(['default', 'brl'].includes(props?.category) &&
			chosenProduct?.slug === props?.slug);

	// Set the classes
	let classes = `product-select layout-${props?.layout || 'horizontal'}`;
	if (props?.className) {
		classes += ` ${props?.className}`;
	}
	if (isSelected && !multiple) {
		classes += ' selected';
	}

	/**
	 * Handles the mouse over event on the button.
	 * If the button is selected and the mouse has been clicked and hovered,
	 * or if the button is selected but has not been clicked or hovered,
	 * it sets the `showRemove` state to true.
	 */
	const handleMouseOver = () => {
		if (!removeAllowed) return;

		if (
			(hasClicked && mouseHasHovered.current && isSelected) ||
			(isSelected && !hasClicked && !mouseHasHovered.current)
		) {
			setShowRemove(true);
		}
	};

	/**
	 * Handles the mouse leave event on the button.
	 * If the button is selected and the mouse has been clicked, it sets `mouseHasHovered` to true.
	 * It also sets the `showRemove` state to false.
	 */
	const handleMouseLeave = () => {
		if (!removeAllowed || !isSelected) return;

		if (hasClicked) {
			mouseHasHovered.current = true;
		}

		setShowRemove(false);
	};

	/**
	 * Handle the click event
	 * @param {object} e - The event object
	 * @returns {void}
	 **/
	const handleClick = e => {
		e.preventDefault();
		setHasClicked(true);
		onSelect(props?.slug, showRemove);
	};

	// Update the quantity if the product is selected and multiple is allowed
	useEffect(() => {
		if (!isSelected || !multiple) return;
		const qty = chosenAddOns?.find(a => a?.slug === props?.slug)?.quantity;
		setQuantity(qty);
		// eslint-disable-next-line
	}, [isSelected]);

	return (
		<Wrap
			className={classes}
			data-cy="fiber-product-select"
			$layout={props?.layout}>
			{props?.badge && props?.layout === 'vertical' && (
				<Badge>
					<span>{props?.badge}</span>
				</Badge>
			)}

			<Inner>
				<Image
					category={props?.category}
					$large={props?.layout === 'vertical'}>
					{(image?.file?.url && (
						<LazyImage
							{...image}
							width={300}
							height={300}
							sizes={[
								{ w: 300, h: 300 },
								{ w: 120, h: 120 },
							]}
							fit="fill"
						/>
					)) || (
						<MissingImage
							category={props?.category || 'default'}
							layout={props?.layout || 'horizontal'}
						/>
					)}
				</Image>
				<Content>
					{title && (
						<>
							{(props?.layout === 'vertical' && (
								<Heading
									level="h3"
									className="product-select__title">
									{title}
								</Heading>
							)) || (
								<Title className="product-select__title">
									{title}
								</Title>
							)}
						</>
					)}
					{props?.category !== 'brl' && description && (
						<p className="product-select__desc">{description}</p>
					)}

					{props?.category !== 'add-on' && (
						<Points
							included={included || []}
							notIncluded={notIncluded}
							className="product-select__features"
						/>
					)}

					<ProductPrice {...props} />
				</Content>
				{props?.layout !== 'vertical' && (
					<Action
						onMouseOver={handleMouseOver}
						onMouseLeave={handleMouseLeave}>
						{(multiple && (
							<QtySelect
								min={1}
								max={5}
								quantity={quantity}
								setQuantity={setQuantity}
								onSelect={value => onSelect(value)}
							/>
						)) || (
							<Button
								outlined="true"
								icon={
									(isSelected && showRemove && faXmark) ||
									(isSelected && !showRemove && faCheck)
								}
								disabled={isSelected && !showRemove}
								className={isSelected && showRemove && 'red'}
								onClick={handleClick}>
								{(isSelected && showRemove && 'Fjern') ||
									(isSelected && !showRemove && 'Valgt') ||
									'Velg'}
							</Button>
						)}
					</Action>
				)}
			</Inner>
		</Wrap>
	);
}
